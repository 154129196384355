<template>
  <v-data-table
    :headers="headers"
    :items="allReview"
    :items-per-page="10"
    class="elevation-2"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>All Reviews</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        color="primary"
        v-if="loading"
        top
      />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom v-if="item.review_status == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            icon
            v-bind="attrs"
            v-on="on"
            @click="upadateReviewStatus(item.id, { review_status: 2 })"
          >
            <v-icon> fas fa-thumbs-up </v-icon>
          </v-btn>
        </template>
        <span>Approve review</span>
      </v-tooltip>
      <div v-if="item.review_status == 2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              icon
              v-bind="attrs"
              v-on="on"
              @click="upadateReviewStatus(item.id, { review_status: 1 })"
            >
              <v-icon> fas fa-thumbs-down </v-icon>
            </v-btn>
          </template>
          <span>Dis-approve review</span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Property from "../../api/Property";
export default {
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: false,
          value: "id",
        },
        { text: "Name", value: "name", sortable: false },
        { text: "City", value: "city", sortable: false },
        { text: "Rating", value: "rating", sortable: false },
        { text: "Contact", value: "contact", sortable: false },
        { text: "Review", value: "review", sortable: false },
        {
          text: "Review Status",
          value: "review_status",
          sortable: false,
        },

        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState({
      allReview: (state) => state.property.allReview,
    }),
  },
  mounted() {
    this.showAllReview();
  },
  methods: {
    ...mapActions(["showAllReview"]),
    upadateReviewStatus(id, status) {
      this.loading = true;

      Property.upadateReviewStatus(id, status)
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code == 201) {
            this.loading = false;
            this.text = response.data.message;
            this.snackbar = true;
            this.snackbar_color = "success";
            this.showAllReview();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.text = err.response.data.message;
          this.snackbar = true;
          this.snackbar_color = "error";
        });
    },
  },
};
</script>

<style></style>
