var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.allReview,"items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("All Reviews")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"color":"primary","top":""}}):_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.review_status == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.upadateReviewStatus(item.id, { review_status: 2 })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fas fa-thumbs-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve review")])]):_vm._e(),(item.review_status == 2)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.upadateReviewStatus(item.id, { review_status: 1 })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fas fa-thumbs-down ")])],1)]}}],null,true)},[_c('span',[_vm._v("Dis-approve review")])])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }